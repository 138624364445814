import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { rhythm, scale } from '../utils/typography'

class SingleTagTemplate extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const { posts, tagName } = this.props.pageContext
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={`${tagName} posts`}
          description={`Posts tagged with ${tagName}`}
        />
        <h1>Posts tagged '{`${tagName}`}'</h1>
        <hr />
        {posts && (
          <ul>
            {posts.map((post, index) => {
              const { path, url, title } = post.frontmatter
              return (
                <li key={index}>
                  <Link to={url}>{title}</Link>
                </li>
              )
            })}
          </ul>
        )}
      </Layout>
    )
  }
}

export default SingleTagTemplate

export const pageQuery = graphql`
  query SingleTagIndex {
    site {
      siteMetadata {
        title
        author
      }
    }
  }
`
