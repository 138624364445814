import Typography from 'typography'
import lincolnTheme from 'typography-theme-lincoln'

// import ThemeDeYoung from 'typography-theme-de-young'

// ThemeDeYoung.googleFonts = [
//   {
//     name: 'Alegreya Sans',
//     styles: ['500'],
//   },
//   {
//     name: 'Alegreya',
//     styles: ['400', '400i', '700', '700i'],
//   },
//   {
//     name: 'Fira Mono',
//     styles: ['400'],
//   },
// ]

// ThemeDeYoung.headerFontFamily = ['Alegreya Sans', 'sans-serif']
// ThemeDeYoung.bodyFontFamily = ['Alegreya', 'serif']
// ThemeDeYoung.baseLineHeight = 1.8

// ThemeDeYoung.overrideThemeStyles = ({ rhythm }) => ({
//   'h1,h2,h3,h4': { lineHeight: 1.3 },
//   a: {
//     color: `#BD1818`,
//   },
//   'a.gatsby-resp-image-link': { boxShadow: `none` },
//   pre: {
//     marginLeft: 0,
//     marginRight: 0,
//     marginTop: 0,
//     marginBottom: '1.65rem',
//     padding: 0,
//     fontSize: '0.85rem',
//     lineHeight: 1.42,
//     backgroundColor: `hsla(0,0%,0%,0.04)`,
//     borderRadius: '3px',
//     overflow: 'auto',
//     padding: '1.65rem',
//   },
//   code: {
//     fontFamily: 'Fira Mono, monospace',
//     lineHeight: 1.2,
//     fontSize: '0.7rem',
//     color: `#BD1818`,
//   },
//   'pre > code': { color: 'inherit' },
//   blockquote: {
//     fontStyle: 'normal',
//     borderLeft: `${rhythm(2 / 16)} solid #596DCF`,
//     fontSize: '0.9rem',
//     fontFamily: 'Alegreya Sans, sans-serif',
//   },
//   li: {
//     marginBottom: '0px',
//   },
//   p: {
//     marginBottom: '1.4rem',
//   },

//   ul: {
//     marginBottom: '1.4rem',
//   },
// })

// delete ThemeDeYoung.googleFonts

// const typography = new Typography(ThemeDeYoung)

// // Hot reload typography in development.
// if (process.env.NODE_ENV !== `production`) {
//   typography.injectStyles()
// }

// export default typography
// export const rhythm = typography.rhythm
// export const scale = typography.scale

lincolnTheme.baseFontSize = '18px'
lincolnTheme.baseLineHeight = 1.7
lincolnTheme.overrideThemeStyles = (
  { adjustFontSizeTo, scale, rhythm },
  options
) => {
  const linkColor = '#950451'
  // const linkColor = '#49618c'
  return {
    a: {
      color: linkColor,
      textDecoration: 'none',
      textShadow:
        '.03em 0 #fff,-.03em 0 #fff,0 .03em #fff,0 -.03em #fff,.06em 0 #fff,-.06em 0 #fff,.09em 0 #fff,-.09em 0 #fff,.12em 0 #fff,-.12em 0 #fff,.15em 0 #fff,-.15em 0 #fff',
      backgroundImage: `linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 1px, ${linkColor} 1px, ${linkColor} 2px, rgba(0, 0, 0, 0) 2px)`,
    },
    'a.gatsby-resp-image-link': { boxShadow: `none` },
    'a:hover,a:active': {
      textShadow: 'none',
      backgroundImage: 'none',
    },
    'h1,h2': {
      marginTop: rhythm(1.3),
      marginBottom: rhythm(1.3),
      lineHeight: rhythm(1.3),
    },
    'h3,h4': {
      marginTop: rhythm(1),
      marginBottom: rhythm(1),
      lineHeight: rhythm(1.1),
    },
    pre: {
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      marginBottom: '1.65rem',
      padding: 0,
      fontSize: '1',
      lineHeight: 1.1,
      backgroundColor: `hsla(0,0%,0%,0.04)`,
      borderRadius: '3px',
      overflow: 'auto',
      padding: '1.65rem',
    },
    code: {
      fontFamily: 'Fira Mono, monospace',
      lineHeight: 1.2,
      fontSize: '0.7rem',
      color: `#BD1818`,
    },

    li: {
      marginBottom: '0px',
    },
    blockquote: {
      fontStyle: 'normal',
      borderLeft: `${rhythm(2 / 16)} solid #f2e366`,
      fontSize: '0.9rem',
      fontFamily: 'Alegreya Sans, sans-serif',
    },
  }
}

const typography = new Typography(lincolnTheme)
export default typography
